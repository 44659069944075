import styled from 'styled-components';

export const CategoryWrapper = styled.div`
    display: ${ ({ open }) => open };
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: ${(props) => props.position };
    background-color: ${({ theme }) => theme.colors.white };
`;

export const CategoryList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px 10px 10px 0;
    background-color: ${({ theme }) => theme.colors.white };
    border-top: 1px solid ${({ theme }) => theme.colors.grey };
`;

export const CategoryElement = styled.li`
   color: ${ ({ theme }) => theme.colors.main };
   font-weight: 700;
   cursor: pointer;
`;

export const StyledLabel = styled.label`
    width: 100%;
    display: flex;
    font-size: 12px;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.main };
    margin: 5px 0;

    input {
        margin-right: 5px;
    }
`;

export const StyledInput = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const ColorCategory = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${ ({ color }) => color };
    margin-right: 5px;
`;

export const CateogryTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 10px 15px;
`;

export const Title = styled.h3`
    color: ${({ theme }) => theme.colors.main };
    font-weight: 300;
    font-size: 18px
`;

export const StyledCheckbox = styled.div`
    width:15px;
    height: 15px;
    background-image: ${ ({ url }) => `url('${ url }')`};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0px 15px;
    transition: all 150ms;
`;

export const CloseButton = styled.div`
    cursor: pointer;
    position: relative;
    width: 15px;
    height: 15px;

    :hover:after, :hover:before {
        background-color: ${({ theme }) => theme.colors.colorMain }
    }

    ::after, ::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 15px;
        background-color: ${({ theme }) => theme.colors.main };
        transform: rotate(45deg);
        cursor: pointer;
    }

    ::before {
        transform: rotate(-45deg);
    }

`;
