import React, { useContext, useEffect, useState } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';
import { CategoryList, CategoryWrapper, StyledLabel, CateogryTitle, Title, StyledInput, StyledCheckbox, CloseButton } from './SelectedCategoryStyle';
import openEye from '../../img/eye.png';
import closeEye from '../../img/private.png';
import Checkbox from './Checkbox';
import uuid from '../../dane/uuid';

const SelectCategory = () => {

    const state = useContext(GlobalStateContext);
    const dispatch = useContext(GlobalDispatchContext);
    const features = state.fetchFeatures;
    const openCategory = state.openCategory;
    const open = state.openArticleList;
    const [ all, setAll ] = useState(true);
    const [selectedCategorys, setSelectedCategorys] = useState([]);
    const [position, setPosition] = useState(`50px`);
    const [eye, setEye ] = useState(openEye);


    const findFeatures = (categorys, feauters) => {
        const categoryFeauter = [];
        categorys.map(category => {
            feauters.map( feauter => {
                const catFeauter = feauter.values_.category;
                if(catFeauter === category) {
                    categoryFeauter.push(feauter);
                }
                return null;
            })
            return null;
        })
        return categoryFeauter;
    }

    useEffect(() => {
        if(!all) {
            dispatch({type: 'CHANGE_FEATURES', payload: findFeatures(selectedCategorys, features )});
        } 
        if(all) {
            dispatch({type: 'CHANGE_FEATURES', payload: state.fetchFeatures});
        }
    }, [ selectedCategorys ]);

    useEffect(() => {
        if(!open) {
           setPosition(`50px`);
    
        } else {
    
            if(window.innerWidth > 920) {
                setPosition(`calc(30% + 50px)`);  
            } else {
                setPosition(`calc(50% + 50px)`);
            }
            if (window.innerWidth > 2000) {
                setPosition(`660px`);
            }
        }
    }, [ open ]);

    const handleChooseCategory = (e) => {

        const element = e.currentTarget;
        const category = element.value;
        const checked = element.checked;

        if(selectedCategorys.indexOf(category) === -1) {
            setSelectedCategorys([ ...selectedCategorys, category]);
        } else {
            setSelectedCategorys(
                selectedCategorys.filter( item => {
                    return item !== category;
                }
            ))
        }
        
        if(category !== 'wszystkie') {
            setAll(false);
            setEye(closeEye);
            
        }else {
            setAll(!all);
            setSelectedCategorys([]);
            const check = document.querySelectorAll('.category');

            for(let i = 0; i < check.length; i++) {
                check[i].checked = false;
            }

            if(checked) {
                setEye(openEye);
            } else {
                setEye(closeEye)
            }
        }
    }

    const handleCloseCategory = () => {
        dispatch({type: 'OPEN_CATEGORY'});
    }

    const findCategory = (category) => {
        if(category === 'szczytno') {
            return 'Szczytno';
        };
        if(category === 'przasnysz'){
            return 'Przasnysz'
        };
        if(category === 'lidzbark') {
            return 'Lidzbark Warmiński'
        }
        if(category === 'miasteczko') {
            return 'Miasteczko Śląskie';
        }
        if(category === 'rzeszow') {
            return 'Rzeszów'
        }
    }

    return (
        <CategoryWrapper open = { openCategory ? 'flex' : 'none' } position = { position } >
            <CateogryTitle>
                <Title>Filtr</Title>
                <CloseButton onClick = { handleCloseCategory } />
            </CateogryTitle>
            <CategoryList>
                
                    {
                        uuid.map((element, id) =>  {
                            return (
                                <Checkbox key = { id } handleChooseCategory = { handleChooseCategory } name = { element.category } value = { element.category } labelName = { findCategory(element.category) } allChecked = { all } color = { element.color } />)
                            })
                    }
                    <div>
                        <StyledLabel>
                            <StyledInput type='checkbox' onChange = { handleChooseCategory } value = 'wszystkie' checked = { all }/>
                            <StyledCheckbox url = { eye } />
                            wszystkie
                        </StyledLabel>
                    </div>
            </CategoryList>
        </CategoryWrapper>
    )
};

export default SelectCategory;
