import React, { useContext } from 'react';
import { GlobeWrapper } from './buttonStyle';
import globe from '../../img/globe.png';
import { GlobalDispatchContext } from '../../GlobalContextProvider';

const Globe = () => {
    const dispatch = useContext(GlobalDispatchContext);

    const handleGoBack = () => {
        dispatch({ type: 'ZOOM_CHANGE', payload: 7 });
        dispatch({ type: 'CENTER_CHANGE', payload: [ 1920001, 6810000 ]});
        dispatch({ type: 'OPEN_ARTICLE', payload: false});
    };

    return (
        <GlobeWrapper url = { globe } onClick = { handleGoBack } />
    )
};

export default Globe;
