import React, { useContext } from 'react';
import { GlobalStateContext, GlobalDispatchContext } from '../../GlobalContextProvider';
import { ArticleWrapper, ArticleTitle, ArticleContent, ArticleFoto, StyledLink, ButttonBack, ArticleFotoWrapper, HeaderWrapper, ZoomOnMap, ValueTree } from './StyledArticle';
import zoomIn from '../../img/zoom.png';
import uuid from '../../dane/uuid';

const Article = ( ) => {
    const state = useContext(GlobalStateContext);
    const content = state.articleContent;
    const opis = content.content;
    const dispatch = useContext(GlobalDispatchContext);

    const getColor = (type) => {
        for( let i=0; i<uuid.length; i++){
            if(uuid[i].category === type) {
                return uuid[i].color;
            }
        }

    }

    const handleBackButton = () => {
        dispatch({ type: 'OPEN_ARTICLE', payload: false });
        dispatch({type: 'SET_ACTIVE_FEATURE_ID', payload: '0'})
    }

    const handleZoomOnMap = () => {
        dispatch({ type: 'CENTER_CHANGE', payload: content.point});
        dispatch({ type: 'ZOOM_CHANGE', payload: 15 });
    }

    return (
        <ArticleWrapper>
            <HeaderWrapper>
                <ZoomOnMap onClick = { handleZoomOnMap } url = { zoomIn }>Przybiliż na mapie</ZoomOnMap>
                <ButttonBack onClick = { handleBackButton } />
            </HeaderWrapper>
            <ArticleFotoWrapper>
                <ArticleFoto url = { content.foto } />
            </ArticleFotoWrapper>
            {/* <ArticleTitle>{ content.title }</ArticleTitle>
            <ValueTree>Roczna wartość pracy drzewa:</ValueTree>
            <p><strong>{ content.wartosc } zł</strong></p> */}
            <ArticleContent dangerouslySetInnerHTML = {{ __html: opis}} />
            <StyledLink href = { content.link } target="_blank" color = {getColor(content.category)} >Przeczytaj więcej</StyledLink>
        </ArticleWrapper>
    )
};

export default Article;
