import React, { useContext, useState, useEffect } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';
import { Map, WmsLayer, Zoom, ClusterSource, clusterText, clusterStackStyle, Select } from '@avinet/react-openlayers';
import { StyledWrapper } from './StyledMapCompnent';
import useFeatures from '../../hooks/useFeatures';
import useStyle from '../../hooks/useStyle'
import CopyGridAvi from '../copyRight/CopyGridAvi';

const MapComponent = () => {
  const state = useContext(GlobalStateContext);
  const point = state.point;
  const zoom = state.zoom;
  const dispatch = useContext(GlobalDispatchContext);
  const [loading, setLoading] = useState(false);
  const { features } = useFeatures();
  const {featureStyle} = useStyle();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
      dispatch({ type: 'CHANGE_FEATURES', payload: features });
      dispatch({ type: 'FETCH_FEATURES', payload: features });
    }, 2000);

    return () => clearTimeout(timer);
  }, [dispatch, features]);

  return (
    <StyledWrapper>
      <Map
      center = { point }
      zoom = { zoom }
      minZoom = { 2 }
      maxZoom = { 18 } 
      >
      <Zoom />
      <WmsLayer
      baseLayer
      id = 'OSM-POSITRON'
      key = 'OSM-POSITRON'
      name = 'Open Street Map-LIGHT (WMS)'
      uri = 'https://osm.mapserv.gridw.pl/service?tiled=true&'
      layerName = 'osm_positron'
      //projCode='2180'
      version = '1.3.0'
      zIndex = { -1 }
      />
      <ClusterSource
      layerName = 'clusterSourceLayer'
      features = { loading ? state.allfeatures : null }
      clusterThreshold = { 5 }
      selectable = { false }
      styleFn = { () =>
        clusterStackStyle({
          clusterLabelText: clusterText({ color: '#333' }),
          stackOffsetX: 4,
          typeField: 'type',
          featureStyle
        })}
      >
      <Select 
        onSelect = { features => {
        if(features.length === 1) {
          const feature = features[0];
          const geometry = features[0].values_.features[0].values_.geometry.flatCoordinates;
          dispatch({type: 'OPEN_ARTICLE', payload: true });
          dispatch({type: 'OPEN_ARTICLELIST', payload: true});
          dispatch({type: 'SET_ACTIVE_FEATURE', payload: feature})
          dispatch({type: 'SET_ACTIVE_FEATURE_ID', payload: feature.values_.features[0].ol_uid})
          dispatch({type: 'FILL_ARTICLE_CONTENT', title: features[0].values_.features[0].values_.title, content: features[0].values_.features[0].values_.content, link: features[0].values_.features[0].values_.link, foto: features[0].values_.features[0].values_.foto, point: geometry, wartosc: features[0].values_.features[0].values_.wartosc, category: features[0].values_.features[0].values_.category });
          } else {
          dispatch({type: 'OPEN_ARTICLE', payload: false });
        }
        return false;
        }}
      />
      </ClusterSource>
    </Map>
    <CopyGridAvi />
  </StyledWrapper>
  )
};

export default MapComponent;
