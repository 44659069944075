import styled from 'styled-components';

export const CopyGridAviWrapper = styled.div`
    color: ${({theme}) => theme.colors.main};
    font-size: 12px;
    position: absolute;
    bottom: 5px;
    left: ${(props) => props.position};
    display: flex;

    p {
        margin: 0 3px;
    }
`;

export const Link = styled.a`
    color: ${({theme}) => theme.colors.main};
    curosor: pointer;
`;

export const OpenStreetMapWrapper = styled.div`
    font-size: 12px;
    position: absolute;
    bottom: 220px;
    right: -20px;
    transform: rotate(-90deg);
`;