import React, { useContext } from 'react';
import { GlobalDispatchContext } from '../../GlobalContextProvider';
import { StyledLogo, StyledWrapper, StyledTitle, LgogWrapper, StyledSubtitle , StyledTitleWrapper} from './StyledArticleCard';
import { Style, Circle, Fill, Stroke } from 'ol/style';
import uuid from '../../dane/uuid';

const ArticleCard = (props) => {
    const { opis, title, point, foto, link, feature, wartosc, category, grafika } = props;
    const dispatch = useContext(GlobalDispatchContext);

    console.log(feature);

    const findCategory = (category) => {
        if(category === 'szczytno') {
            return 'Szczytno';
        };
        if(category === 'przasnysz'){
            return 'Przasnysz'
        };
        if(category === 'lidzbark') {
            return 'Lidzbark Warmiński'
        }
        if(category === 'miasteczko') {
            return 'Miasteczko Śląskie';
        }
        if(category === 'rzeszow') {
            return 'Rzeszów'
        }
    }

    const findColor = (category) => {
        for(let i = 0; i<uuid.length; i++) {
            if(uuid[i].category === category) {
                return uuid[i].color;
            }
        }
    }
    

    return (
        <StyledWrapper onClick = {()=> {
                  dispatch({type: 'OPEN_ARTICLE', payload: true});
                  dispatch({type: 'FILL_ARTICLE_CONTENT', title: title, content: opis, foto: foto, link: link, point: point, wartosc: wartosc, category: category });
                  dispatch({type: 'SET_ACTIVE_FEATURE', payload: feature});
                  dispatch({type: 'SET_ACTIVE_FEATURE_ID', payload: feature.ol_uid})
                  feature.setStyle(()=> {
                    return new Style({
                        image: new Circle({
                            radius: 10,
                            fill: new Fill('#000'),
                            stroke: new Stroke({ color: 'red', width: 1.5 })
                        })
                        })
                  });
              }
            }
        >
            <LgogWrapper>
                <StyledLogo url = { grafika } />
            </LgogWrapper>
            <StyledTitleWrapper>
                <StyledTitle color = {findColor(category)}>{ title }</StyledTitle>
                <StyledSubtitle>{findCategory(category)}</StyledSubtitle>
            </StyledTitleWrapper>
        </StyledWrapper>
    )
};

export default ArticleCard;
