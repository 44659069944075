import React, { useState, useEffect } from 'react';
import { StyledInput, StyledLabel, StyledCheckbox, ColorCategory } from './SelectedCategoryStyle';
import openEye from '../../img/eye.png';
import closeEye from '../../img/private.png';

const Checkbox = ({ value, color, name, labelName, handleChooseCategory, allChecked }) => {

    const [eye, setEye ] = useState(closeEye);

    const handleCheckbox = ( e ) => {
        const element = e.currentTarget;
        const value = element.querySelector('input').checked;
        if(value) {
            setEye(openEye);
        } else {
            setEye(closeEye);
        }
    }

    useEffect(() => {
        if(allChecked) {
            setEye(closeEye);
        } 
    }, [ allChecked ]);

    return (
        <div>            
            <StyledLabel onClick = { handleCheckbox }>
                <StyledInput  type='checkbox' className = 'category' name = { name } value = { value } onChange = { handleChooseCategory }  />
                <StyledCheckbox url = { eye } />
                <ColorCategory color = { color } />
                { labelName }
            </StyledLabel>
        </div>

    )
};

export default Checkbox;
