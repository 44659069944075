import React, { useContext } from 'react';
import { GlobalStateContext, GlobalDispatchContext } from '../../GlobalContextProvider';
import { StyledWrapper, SortingWrapper, SelectData } from './StyledArticleList';
import ArticleCard from '../ArticleCard/ArticleCard';
import Article from '../ArticleComponent/Article';
import ArticleListOpen from '../buttons/ArticleListOpen';

const ArticleList = () => {
    const state = useContext(GlobalStateContext);
    const dispatch = useContext(GlobalDispatchContext);
    const features = state.allfeatures;
    const open = state.openArticleList;
    const openArticle = state.singleArticle;
    const singleArticle = state.singleArticle;

    const sortA = (a, b) => {
        if(a.values_.title < b.values_.title) return -1;
        if(a.values_.title > b.values_.title) return 1;
        return 0;
    };

    const sortZ = (a, b) => {
        if(a.values_.title < b.values_.title) return 1;
        if(a.values_.title > b.values_.title) return -1;
        return 0;
    }

    const handleChangeSelect = (e) => {
        const valueSelect = e.currentTarget.value;
        if(valueSelect === 'a') {
            const newFeatures = features.sort(sortA);
            dispatch({ type: 'FETCH_FEATURES', payload: newFeatures });
        } else if(valueSelect === 'b') {
            const newFeatures = features.sort(sortZ);
            dispatch({ type: 'FETCH_FEATURES', payload: newFeatures });
        }
    }

    return (
        <>
            <StyledWrapper open = { open ? `flex` : `none` }>
                <SortingWrapper open = {singleArticle ? `none` : `flex`}>
                    <SelectData onChange = {handleChangeSelect}>
                        <option>Sortuj</option>
                        <option value = "a">Nazwa A - Z</option>
                        <option value = "b">Nazwa Z - A</option>
                    </SelectData>
                </SortingWrapper>
                {   
                    openArticle ?
                    <Article /> :
                    features.map((feature, i) => {
                        return <ArticleCard key = { i } title = { feature.values_.title } opis = { feature.values_.content } point = { feature.values_.geometry.flatCoordinates } foto = { feature.values_.foto } link = { feature.values_.link } feature = { feature } wartosc = { feature.values_.wartosc} category = { feature.values_.category } grafika = {feature.values_.grafika} /> })
                }

            </StyledWrapper> 
            <ArticleListOpen /> 
        </>
    )
}

export default ArticleList;
