const uuid = [
    {
        uuid: "d7de03a2-dcff-476c-9664-b1307e4a9b3d",
        category: 'rzeszow',
        name: 'rzeszow',
        color: '#0ec896'
    },
    {
        uuid: "7195807f-46cd-463a-8fed-ebc367843ac0",
        category: 'lidzbark',
        name: 'lidzbark',
        color: '#dc5098'
    },
    {
        uuid: "c97e6482-4875-49e3-bcda-ebfaaf8f69b2",
        category: 'miasteczko',
        name: 'miasteczko',
        color: '#f39000'
    },
    {
        uuid: "49c0984a-6ced-43fc-a34c-ea636252678f",
        category: 'szczytno',
        name: 'szczytno',
        color: '#8d5cd1'
    },
    {
        uuid: "261839d7-f53a-4203-8539-aa4dacfefea5",
        category: 'przasnysz',
        name: 'przasnysz',
        color: '#cbd119'
    }
];

export default uuid;
